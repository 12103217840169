<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        In this warehouse scenario, what recommendations might you offer that would prevent this
        problem from re-occurring in next year's warehouse inventory?
      </p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.studentAnswer" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'OleMiss116_PL9_Q2part3',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
};
</script>
